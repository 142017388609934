import { Container, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import Layout from '../components/layout'
import MediaCenterCard from '../components/mediacentercard'
import NewHeader from '../components/NewHeader'
import t from '../images/gallery/128112017160804.jpg'
import fourth from '../images/gallery/13232017123352.jpg'
import first from '../images/gallery/1327201710223.jpg'
import s from '../images/gallery/1524201712053.jpg'
import sixth from '../images/gallery/1916201660353.jpg'
import seventh from '../images/gallery/4215201644126.jpg'
import MembershipImage from '../images/media.jpg'
import SEO from '../components/seo'

const useStyles = makeStyles(theme => ({
  marginMainTop: {
    marginTop: -120,
    marginBottom: 84,
  },
  mt24: {
    marginTop: 24,
  },
  white: {
    color: '#fff',
  },
  paddingAll24: {
    padding: 24,
  },
}))
const MediaCenter = () => {
  const classes = useStyles()
  return (
    <Layout>
      <SEO title="Media Center" />
      <NewHeader title="Media Center" image={MembershipImage} />
      <div className={classes.marginMainTop}>
        <Container>
          <Grid container spacing={3} className={classes.mt24}>
            <Grid item md={4} xs={12}>
              <MediaCenterCard
                title="Meeting of IRS (IT) Probationers with Hon'ble Minister of State for Finance on 22.03.17"
                image={first}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <MediaCenterCard
                title="Felicitation of Archer Abhishek Verma by CBDT"
                image={s}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <MediaCenterCard
                title="Chairman & Member(CBDT) congratulating the officers on winning 'Bronze Medal' at 37th IITF, 2017"
                image={t}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <MediaCenterCard
                title="Ms. Nishi Singh, Member(P&V), CBDT greeting Hon'ble President of India"
                image={fourth}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <MediaCenterCard
                title="Pr.CCIT, Chandigarh, Shri Rajender Kumar receiving Rajbhasa Kirti Puraskar from Hon'ble President."
                image={sixth}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <MediaCenterCard
                title="Department of Revenue Pavilion at Make In India Week, Mumbai set up by CBD"
                image={seventh}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </Layout>
  )
}

export default MediaCenter
